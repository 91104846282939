
<div class="d-flex flex-column h-100">
  <app-nav-bar></app-nav-bar>

  <div class="container flex-grow-1 flex-shrink-1">
    <router-outlet></router-outlet>
  </div>

  <app-footer></app-footer>
</div>
