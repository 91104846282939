import { Component, OnInit, ViewChild } from '@angular/core';
import { Auth0Service } from 'src/app/auth0-service.service';

@Component({
  selector: 'app-login-popup',
  templateUrl: './login-popup.component.html',
  styleUrls: ['./login-popup.component.css']
})
export class LoginPopupComponent implements OnInit {

  constructor(private auth0service: Auth0Service) { }

  ngOnInit(): void {

  }

  login(email, password){
    console.log(email + " " + password)
    this.auth0service.login(email, password);
  }

}
