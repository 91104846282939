
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs'

import auth0 from 'auth0-js';

@Injectable({
  providedIn: 'root'
})
export class Auth0Service {

  webAuth: any;

  clientId: string //depends on if it is app-a or app-b
  redirectUri: string
  clientSecret: string

  //refreshToken: string

  activeGroup: "group-1" | "group-2" | ""

  groupChanged = new BehaviorSubject(0)

  constructor(private httpClient: HttpClient) {

    if(window.location.href.includes("app-a")){
      this.clientId = 'Ewga67o0HGYTwoqB80b7l7KFu3Fh5sdF'
      this.redirectUri = "https://app-a.dev.precima.io/profile"
      this.clientSecret = "OVYL3EsTBftN-gm_efEiehRLIOqmvnj3RdS36o3TYsO-qcka3oMCTjzDPRar0N9U"
      this.activeGroup = "group-1"
    }
    else{ //would be deployed to app-b
      this.clientId = 'QZHVOwvakLHjoZ4eZS1eSft54ZvsMiNy'
      this.redirectUri = "https://app-b.dev.precima.io/profile"
      this.clientSecret = "jN7LPM26RM-KLWBT_lr3b2V3L_KZRv69ydx4Hf_jn7-OU2UwWeOffVpz12qza91G"
      this.activeGroup = "group-1"
    }

    this.setActiveGroupCookie(this.activeGroup)

    //create a new instance of webAuth to handle logging in, logging out, and getting user information
    this.webAuth = new auth0.WebAuth({
      domain:       'auth.dev.precima.io',
      clientID:     this.clientId,
      redirectUri:   this.redirectUri,
      responseType: 'token id_token code',
      scope: 'openid profile email offline_access ' + this.activeGroup,
      audience: `https://cross-origin-poc.us.auth0.com/api/v2/`
    });
  }
  
  //service to check if a user is authenticated
  isLoggedIn(): Promise<string>{
    return new Promise((resolve, reject)=>{
      this.webAuth.checkSession({scope: 'openid profile email offline_access ' + this.activeGroup}, (err, authResult) => {
        console.log(authResult)
        if(authResult){
          resolve("logged in")
        }
        else{
          reject(err.description)
        }
      })
    })
  }

  //service to get the user information such as their profile picture and email
  getUserInfo(): Promise<any>{
    return new Promise((resolve, reject)=>{
      this.webAuth.checkSession({scope: 'openid profile email offline_access ' + this.activeGroup}, (err, authResult) => {
        if(authResult){
          resolve(authResult.idTokenPayload)
        }
        else{
          reject(err.description)
        }
      })
    })
  }

  getAuthorizationCode(): Promise<any>{
    return new Promise((resolve, reject)=>{
      this.webAuth.authorize({}, (err, authResult) => {
        console.log(authResult)
        if(authResult){
          resolve("logged in")
        }
        else{
          reject(err.description)
        }
      })
    })
  }

  // getRefreshToken(code: string, successCallback, errorCallback){

  //   //call oauth/token with the authentication code
  //   let authorizationCodeExchange = {
  //     grantType: 'authorization_code',
  //     clientID: this.clientId,
  //     clientSecret: this.clientSecret,
  //     code: code,
  //     redirectUri: this.redirectUri
  //   }
  //   console.log(authorizationCodeExchange)
  //   this.webAuth.client.oauthToken(authorizationCodeExchange, (err, authResult) => {
  //     if(err){
  //       console.warn(err)
  //       errorCallback()
  //     }
  //     if(authResult){
  //       console.log(authResult)
  //       this.refreshToken = authResult.refreshToken      
  //       successCallback()
  //     }
  //   })
  // }

  // useRefreshToken() {
  //   if(this.refreshToken == undefined || this.refreshToken.length < 1){
  //     console.log("no refresh token provided")
  //     return
  //   }

  //   //get refreshTokenOptionInstance
  //   let refreshTokenOption = {
  //     clientID: this.clientId,
  //     grantType: 'refresh_token',
  //     refreshToken: this.refreshToken
  //   }
    
  //   this.webAuth.client.oauthToken(refreshTokenOption, (err, authResult) => {
  //     if(err){
  //       console.warn(err)
  //     }
  //     if(authResult){
  //       console.log(authResult)
  //       //update the refresh token
  //       this.refreshToken = authResult.refreshToken
  //     }
  //   })
  // }

  // currentRefreshToken(): string {
  //   return this.refreshToken
  // }

  setActiveGroupCookie(group: "group-1" | "group-2" | "") {
    console.log("setting cookie: ", "group=" + group + "; Domain=precima.io;")
    document.cookie = "group=" + group + "; Domain=precima.io;"
    this.updateActiveGroup()
  }

  updateActiveGroup(){
    let activeGroupValue = document.cookie.split("; ").find((row) => row.startsWith("group="))?.split("=")[1];
    console.log("Updating active group: ", activeGroupValue)
    if(activeGroupValue === "group-1" || activeGroupValue === "group-2" && this.activeGroup !== activeGroupValue){
      this.activeGroup = activeGroupValue
      this.groupChanged.next(0)
    }
  }

  /*
  service to login to the website. Can be configured to use a username as well instead of an email
  takes the user to the redirectUri provided in the constructor when initiating webAuth
  */
  login(email: string, password: string){
    this.webAuth.login({
      realm: 'Username-Password-Authentication', //the database to connect to in which the users are stored
      email: email,
      password: password
    }, (err, authResult) => {
      if(err){
        window.alert(err.description)
        console.log(err)
      }
    });
  }

  //service to logout and return to the home page or login screen of the application
  logout(){
    //clear the refresh token
    //this.refreshToken = undefined
    let homeURL: string;
    if(window.location.href.includes("app-a")){
      homeURL = "https://app-a.dev.precima.io/"
    }
    else{
      homeURL = "https://app-b.dev.precima.io/"
    }
    this.webAuth.logout({
      returnTo: homeURL,
      clientID: this.clientId
    });
  }
}
