import { Component } from '@angular/core';

@Component({
  selector: 'app-external-api',
  templateUrl: './external-api.component.html',
  styleUrls: ['./external-api.component.css'],
})
export class ExternalApiComponent {
  responseJson: string;
  hasApiError = false;

  constructor() {}

  pingApi() {

  }
}
