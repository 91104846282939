import { Component, OnInit, OnDestroy } from '@angular/core';
import { Auth0Service } from 'src/app/auth0-service.service';
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit, OnDestroy {
  profileJson: string = null;
  authenticated: boolean;

  subscription: Subscription

  constructor(private auth0Service: Auth0Service) {}

  ngOnInit() {
    //get the user info anytime the group is changed
    this.subscription = this.auth0Service.groupChanged.subscribe(event => {
      this.getUserInfo()
    })
  }

  ngOnDestroy(){
    this.subscription.unsubscribe()
  }

  getUserInfo(){
    this.auth0Service.getUserInfo().then((res)=>{
      if(res !== "Not logged in"){
        this.authenticated = true

        this.profileJson = JSON.stringify(res, null, 2);
      }
      else{
        this.authenticated = false
      }
    }).catch((error)=>{
      this.authenticated = false
      console.log("Handling error as we received " + error);
    })
  }

  // useRefreshToken(){
  //   this.auth0Service.useRefreshToken()
  // }

  useGroup1(){
    this.auth0Service.setActiveGroupCookie("group-1")
  }
  useGroup2(){
    this.auth0Service.setActiveGroupCookie("group-2")
  }
}
