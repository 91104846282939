import { Component } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent {

  message: string

  constructor() {
    if(window.location.href.includes("app-a")){
      this.message = 'App-a -> connected to "Login test" Auth0 Application'
    }
    else{ //would be deployed to app-b
      this.message = 'App-b -> connected to "My App" Auth0 Application'
    }
  }
}
