import { Component } from '@angular/core';
import { Observable, timer } from 'rxjs';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
})
export class ErrorComponent {

  constructor() {}

  ngOnInit() {
  }

  displayError(){
    console.log("error");
  }
}

