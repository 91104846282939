<h1>Login</h1>
<div class="login-form">
    <h2>Email</h2>
    <input #email type="text">
    <h2>Password</h2>
    <input #password type="password">
    <div class="login-button">
      <button (click)="login(email.value, password.value)">Login</button>   
    </div>
</div>
